import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MarturiilePage = () => (
  <Layout>
    <SEO title="Mărturiile" />
    <div className="drawer article-content">
      <h1 className="article-title">Mărturiile</h1>

      <section>
        <p>
          Dincolo de tradiția de a oferi ceva invitațiilor, mărturiile sunt
          practic înfățișarea amintiriilor de la nunta voastră.
        </p>
        <p>
          Probabil tu îți vei aminti fiecare detaliu sau fiecare moment, însă
          invitații vor avea mult mai puține amintiri, însă vor râmâne cu o
          mărturie, care le va aminti mereu invitațiilor de ziua în care au
          participat la nunta voastră. Practic vor asocia mărturia cu toate
          sentimentele și emoțiile din ziua voastră specială.
        </p>
        <p>
          Dacă englezii și francezii obișnuiau să ofere bomboniere și italenii
          au dus tradiția mai departe oferind diverse cadouri frumos ambalate,
          acum cred că sunt extrem de multe oferte, astfel încât să putem găsi
          variante potrivite luând în calcul tematica nunții, bugetul și
          pasiunile mirilor.
        </p>
      </section>

      <h3 className="paragh-title">
        Plecând de la tradițiile din trecut, când zahărul era considerat bogăție
        și era oferit ca mărturie ne gândim și noi la variantele dulci pe care
        le avem:
      </h3>
      <section>
        <ul>
          <li>borcănele cu diverse bomboane</li>
          <li>borcănele cu miere</li>
          <li>
            gem sau dulceață (mi se par idei bune, dacă sunt făcute de tine sau
            de cineva din familia ta)
          </li>
          <li>
            ciocolată/bomboane personalizată, fie personalizare direct pe
            bomboană, fie doar pe ambalaj
          </li>
        </ul>
      </section>

      <h3 className="paragh-title">Alte variante de mărturii comestibile :)</h3>
      <section>
        <ul>
          <li>punguțe cu ceai sau cafea/ciocolată caldă</li>
          <li>recipiente/solnițe drăguțe cu diverse condimente</li>
          <li>
            sticluțe cu diverse băuturi, o variantă bună dacă produsele provin
            din producția propie
          </li>
          <li>sticluțe cu ulei de măsline</li>
        </ul>
      </section>

      <h3 className="paragh-title">Alte idei:</h3>
      <section>
        <ul>
          <li>
            poze cu voi împreună cu invitații sau doar cu invitații. Aici sunt
            numeroase opțiuni de photobooth
          </li>
          <li>
            ghivece cu flori, de exemplu cactuși sau suculente, pe care le
            puteți folosi și la decorul mesei
          </li>
          <li>
            vaze cu flori, care de asemenea pot fi folosite și pentru decor și
            la sfârșit invitații pot lua vaza acasă, mai potrivită pentru nunți
            cu mai puțini invitați
          </li>
          <li>semințe de flori</li>
          <li>semne de carte</li>
          <li>evantai, dacă nunta ta este în aer liber și este prea cald</li>
          <li>desfăcător de sticle de vin sau bere</li>
          <li>rame foto</li>
          <li>un glob personalizat, în cazul în care nunta are loc iarna</li>
        </ul>
      </section>

      <section>
        <p>
          Va trebui să te gândești la tematică, locație eventual (de exemplu
          eventaie pentru o nuntă în aer liber, vara), buget și numărul de
          invitați (dacă vrei ceva personalizat sau ceva făcut de tine, este
          dificil dacă vei avea mulți invitați).
        </p>
        <p>
          Însă poți transforma cu ușurință lucrurile aparent banale în mărturii
          wow, cu mesajul potrivit sau cu un ambalaj deosebit.
          <ul>
            <li>
              de la un borcan umplu cu bomboane la 2 punguțe mici cu bomboane
              diferite, preferatele mirelui și ale miresei
            </li>
            <li>semințe de flori cu un mesaj drăguț “let love grow”</li>
            <li>
              o doză de cola personalizată cu numele invitatului și le folosești
              și ca mod de aranjare la mese (potrivit pentru nunți mai mici).
            </li>
          </ul>
        </p>
        <p>
          Sigur că există o mulțime de opțiuni și alegerea mărturiilor nu ar
          trebui să reprezinte o bătaie de cap suplimentară, doar un simbol,
          care să le aducă invitațiilor aminte de voi, să îl oferi și eventual
          să îl pregătești cu drag.
        </p>
      </section>

      <div className="nav-section">
        <Link to="/candyBar">◀ Candy bar </Link>
        <Link to="/tortul">▶ Cum alegem tortul?</Link>
      </div>
    </div>
  </Layout>
)

export default MarturiilePage
